import update from "immutability-helper";
import {
  SET_LOADING_CONTENT,
  SET_PROPERTIES_ROOMS_LOADING,
  SET_FETCHING_DAYRATE_DATA,
  SET_IS_LOADING,
  SET_DAYRATE_PROPERTIES,
  SET_RATEPLANS_ROOMS,
  SET_DAYRATE_DATA
} from "../actions/dayRateAvailabilityActions";

const initialState = {
  loadingContent: "Loading Properties...",
  propertiesRoomsLoading: true,
  fetchingDayRateData: false,
  isLoading: false,
  dayRateProperties: [],
  ratePlansRoomsIds: [],
  dayRateData: []
};

export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_CONTENT:
      return update(state, { loadingContent: { $set: action.loadingContent } });
    case SET_PROPERTIES_ROOMS_LOADING:
      return update(state, {
        propertiesRoomsLoading: { $set: action.propertiesRoomsLoading }
      });
    case SET_FETCHING_DAYRATE_DATA:
      return update(state, {
        fetchingDayRateData: { $set: action.fetchingDayRateData }
      });
    case SET_IS_LOADING:
      return update(state, { isLoading: { $set: action.isLoading } });
    case SET_DAYRATE_PROPERTIES:
      return update(state, {
        dayRateProperties: { $set: action.dayRateProperties }
      });
    case SET_RATEPLANS_ROOMS:
      return update(state, {
        ratePlansRoomsIds: { $set: action.ratePlansRoomsIds }
      });
    case SET_DAYRATE_DATA:
      return update(state, { dayRateData: { $set: action.dayRateData } });
    default:
      return state;
  }
}
