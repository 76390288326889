import {
  getDayRateProperties,
  getRatePlanAndRoomIds,
  checkDayRate
} from "../api/index";

export const SET_LOADING_CONTENT = "SET_LOADING_CONTENT";
export const SET_PROPERTIES_ROOMS_LOADING = "SET_PROPERTIES_ROOMS_LOADING";
export const SET_FETCHING_DAYRATE_DATA = "SET_FETCHING_DAYRATE_DATA";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_DAYRATE_PROPERTIES = "SET_DAYRATE_PROPERTIES";
export const SET_RATEPLANS_ROOMS = "SET_RATEPLANS_ROOMS";
export const SET_DAYRATE_DATA = "SET_DAYRATE_DATA";

//action creators
export const setLoadingContent = loadingData => ({
  type: SET_LOADING_CONTENT,
  loadingContent: loadingData
});
export const setPropertiesRoomsLoading = propertiesRoomsLoading => ({
  type: SET_PROPERTIES_ROOMS_LOADING,
  propertiesRoomsLoading
});
export const setFetchingDayRateData = bool => ({
  type: SET_FETCHING_DAYRATE_DATA,
  fetchingDayRateData: bool
});
export const setIsLoading = bool => ({
  type: SET_IS_LOADING,
  isLoading: bool
});
export const setDayRateProperties = dayRateProperties => ({
  type: SET_DAYRATE_PROPERTIES,
  dayRateProperties
});
export const setRateplansRoomsDetails = ratePlansRoomsIds => ({
  type: SET_RATEPLANS_ROOMS,
  ratePlansRoomsIds
});
export const setDayRateValues = dayRateData => ({
  type: SET_DAYRATE_DATA,
  dayRateData
});

//async actions
export const asyncGetDayRateProperties = dispatch => {
  return getDayRateProperties().then(
    success => {
      if (success.body) {
        dispatch(setDayRateProperties(success.body.ids));
      }
      dispatch(setPropertiesRoomsLoading(false));
    },
    failure => {
      dispatch(setPropertiesRoomsLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetRatePlansRooms = (propId, dispatch) => {
  dispatch(setPropertiesRoomsLoading(true));
  dispatch(setLoadingContent("Fetching RoomTypes and RatePlans..."));
  return getRatePlanAndRoomIds(propId).then(
    success => {
      if (success.body) {
        dispatch(setRateplansRoomsDetails(success.body));
      }
      dispatch(setPropertiesRoomsLoading(false));
    },
    failure => {
      dispatch(setPropertiesRoomsLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetDayRateData = (queryParams, dispatch) => {
  dispatch(setIsLoading(true));
  dispatch(setFetchingDayRateData(true));
  return checkDayRate(queryParams).then(
    success => {
      if (success.body) {
        dispatch(setDayRateValues(success.body.results));
      }
      dispatch(setIsLoading(false));
    },
    failure => {
      dispatch(setIsLoading(false));
      console.log(failure);
    }
  );
};
