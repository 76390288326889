import {
  searchProperty,
  searchReservationReport,
  intgAuditARI,
  intgAuditARIHBRes,
  getProperty,
  getPropertyImages,
  getPropertyAmenities,
  getAllClients,
  addAClient,
  getSourceCommission,
  updateClientDetails,
  updateSourceCommission,
  addSourceCommission,
  getAllSuppliers,
  blacklistProperty,
  getBlacklistedProperties,
  reListProperty,
  getDirectCommissions,
  addOrUpdateRateplans,
  getAClient,
  updateAClient,
  getTaxes,
  updateTaxes,
  deleteTaxes,
  updatePropertyAmenities,
  deleteAmenity
} from "../api";
import { toast } from "react-toastify";

export const SET_PROPERTY_NAME_TO_SEARCH = "SET_PROPERTY_NAME_TO_SEARCH";
export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";
export const UPDATE_SELECTED_PROPERTY = "UPDATE_SELECTED_PROPERTY";
export const UPDATE_SEARCHED_PROPERTIES = "UPDATE_SEARCHED_PROPERTIES";
export const SET_SEARCH_PROPERTY_LOADING = "SET_SEARCH_PROPERTY_LOADING";
export const SET_RESERVATION_REPORT_LOADING = "SET_RESERVATION_REPORT_LOADING";
export const SET_SHOW_RESERVATION_REPORT = "SET_SHOW_RESERVATION_REPORT";
export const SET_RESERVATION_REPORT = "SET_RESERVATION_REPORT";
export const SET_INTG_AUDIT_ARI_CONTENT = "SET_INTG_AUDIT_ARI_CONTENT";
export const SET_HB_RES_INTG_AUDIT_ARI_CONTENT =
  "SET_HB_RES_INTG_AUDIT_ARI_CONTENT";
export const SET_INTG_AUDIT_ARI_LOADING = "SET_INTG_AUDIT_ARI_LOADING";
export const TOGGLE_INTG_AUDIT_ARI_POPUP = "TOGGLE_INTG_AUDIT_ARI_POPUP";
export const TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP =
  "TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP";
export const SET_INTG_AUDIT_ARI_REQ_PARAMS = "SET_INTG_AUDIT_ARI_REQ_PARAMS";
export const SET_SELECTED_PROPERTY = "SET_SELECTED_PROPERTY";
export const SET_PROPERTY_AMENITIES = "SET_PROPERTY_AMENITIES";
export const SET_PROPERTY_IMAGES = "SET_PROPERTY_IMAGES";
export const UPDATE_REPORT_COLUMNS = "UPDATE_REPORT_COLUMNS";
export const SET_PROPERTY_TAXES = "SET_PROPERTY_TAXES";
export const SET_ALL_CLIENTS = "SET_ALL_CLIENTS";
export const SET_A_CLIENT = "SET_A_CLIENT";
export const SET_ALL_SUPPLIERS = "SET_ALL_SUPPLIERS";
export const SET_SOURCE_COMMISSION = "SET_SOURCE_COMMISSION";
export const SET_RESERVATION_REPORT_REQ_PARAMS =
  "SET_RESERVATION_REPORT_REQ_PARAMS";
export const TOGGLE_BLACKLISTED_PROP_LIST = "TOGGLE_BLACKLISTED_PROP_LIST";
export const SET_BLACKLISTED_PROPS = "SET_BLACKLISTED_PROPS";
export const SET_BLACKLISTED_PROPS_LOADING = "SET_BLACKLISTED_PROPS_LOADING";
export const SET_DIRECT_COMMISSIONS = "SET_DIRECT_COMMISSIONS";
const intimateLoginToast = () =>
  toast.error("Search failed because Session Timed out, Login Again?", {
    onClose: () => {
      sessionStorage.clear();
      window.location.href = "/";
    }
  });

//action creators
export const updateReportColumns = reservationReportColumns => ({
  type: UPDATE_REPORT_COLUMNS,
  reservationReportColumns
});
export const setSearchPropertyBy = payload => ({
  type: SET_PROPERTY_NAME_TO_SEARCH,
  payload
});
export const updateSelectedProperty = property => ({
  type: UPDATE_SELECTED_PROPERTY,
  property
});
export const setSearchPropertyLoading = isSearchPropertyLoading => ({
  type: SET_SEARCH_PROPERTY_LOADING,
  isSearchPropertyLoading
});
export const setSearchReservationReportLoading = isReservationReportLoading => ({
  type: SET_RESERVATION_REPORT_LOADING,
  isReservationReportLoading
});
export const setReservationReportRequestParams = reservationReportReqParams => ({
  type: SET_RESERVATION_REPORT_REQ_PARAMS,
  reservationReportReqParams
});
export const setClientDetails = clientDetails => ({
  type: SET_CLIENT_DETAILS,
  clientDetails
});

export const toggleReservationReport = showReservationReport => ({
  type: SET_SHOW_RESERVATION_REPORT,
  showReservationReport
});
export const updateSearchedProperties = properties => ({
  type: UPDATE_SEARCHED_PROPERTIES,
  properties
});
export const setSelectedProperty = selectedProperty => ({
  type: SET_SELECTED_PROPERTY,
  selectedProperty
});
export const setPropertyTaxes = propertyTaxes => ({
  type: SET_PROPERTY_TAXES,
  propertyTaxes
});
export const setPropertyImages = propertyImages => ({
  type: SET_PROPERTY_IMAGES,
  propertyImages
});
export const setPropertyAmenities = propertyAmenities => ({
  type: SET_PROPERTY_AMENITIES,
  propertyAmenities
});
export const setReservationReport = reservationReports => ({
  type: SET_RESERVATION_REPORT,
  reservationReports
});
export const setIntgAuditARIContent = intgAuditARIContent => ({
  type: SET_INTG_AUDIT_ARI_CONTENT,
  intgAuditARIContent
});
export const setHBResIntgAuditARIContent = hbResintgAuditARIContent => ({
  type: SET_HB_RES_INTG_AUDIT_ARI_CONTENT,
  hbResintgAuditARIContent
});
export const toggleIntgAuditARIPopup = showIntgAuditARIPopup => ({
  type: TOGGLE_INTG_AUDIT_ARI_POPUP,
  showIntgAuditARIPopup
});
export const toggleIntgAuditARIHBResPopup = showIntgAuditARIHBResPopup => ({
  type: TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP,
  showIntgAuditARIHBResPopup
});
export const setIntgAuditARILoading = isIntgAuditARILoading => ({
  type: SET_INTG_AUDIT_ARI_LOADING,
  isIntgAuditARILoading
});

export const setIntgAuditARIReqParams = intgAuditARIReqParams => ({
  type: SET_INTG_AUDIT_ARI_REQ_PARAMS,
  intgAuditARIReqParams
});
export const setAllClients = allClients => ({
  type: SET_ALL_CLIENTS,
  allClients
});
export const setAClient = clientToEdit => ({
  type: SET_A_CLIENT,
  clientToEdit
});
export const setAllSuppliers = allSuppliers => ({
  type: SET_ALL_SUPPLIERS,
  allSuppliers
});

export const setSourceCommission = sourceCommission => ({
  type: SET_SOURCE_COMMISSION,
  sourceCommission
});
export const toggleBlacklistedPropertyList = showBlacklistedProperties => ({
  type: TOGGLE_BLACKLISTED_PROP_LIST,
  showBlacklistedProperties
});
export const setBlackListedProperties = blacklistedProperties => ({
  type: SET_BLACKLISTED_PROPS,
  blacklistedProperties
});
export const setBlackListedPropsLoading = blacklistedPropertiesLoading => ({
  type: SET_BLACKLISTED_PROPS_LOADING,
  blacklistedPropertiesLoading
});
export const setDirectCommissions = directCommissions => ({
  type: SET_DIRECT_COMMISSIONS,
  directCommissions
});

// async actions
export const asyncGetProperty = (propId, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getProperty(propId).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setSelectedProperty(success.body));
      }
      asyncGetPropertyAmenities(propId, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncGetPropertyTaxes = (propName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getTaxes(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setPropertyTaxes(success.body.propertyList));
      }
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncUpdatePropertyTaxes = (propName, callback, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  updateTaxes(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      callback();
      asyncGetPropertyTaxes({ propertyName: propName.propertyName }, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncDeletePropertyTaxes = (propName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  deleteTaxes(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      asyncGetPropertyTaxes({ propertyName: propName.propertyName }, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncUpdatePropertyAmenities = (propName, callback, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  updatePropertyAmenities(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      callback();
      asyncGetPropertyAmenities(
        { propertyName: propName.propertyName },
        dispatch
      );
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncDeletePropertyAmenities = (propName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  deleteAmenity(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      asyncGetPropertyAmenities(
        { propertyName: propName.propertyName },
        dispatch
      );
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetPropertyAmenities = (propId, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getPropertyAmenities(propId).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setPropertyAmenities(success.body.propertyAmenitiesDTOList));
      }
      asyncGetPropertyImages(propId, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncGetPropertyImages = (propId, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getPropertyImages(propId).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setPropertyImages(success.body.propertyImagesList));
      }
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncSearchProperty = (user, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  return searchProperty(user).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(updateSearchedProperties(success.body));
      }
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      dispatch(updateSearchedProperties([]));

      if (
        failure.response &&
        failure.response.body &&
        failure.response.body.errorCode
      ) {
        toast.error(failure.response.body.errorDesc);
        return;
      }
      intimateLoginToast();
    }
  );
};
export const asyncSearchReservationReport = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  dispatch(setReservationReport([]));
  return searchReservationReport(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      dispatch(toggleReservationReport(true));
      if (success.body) {
        const reports = addLeadTime(success.body.reservationReportDetails);
        dispatch(setReservationReport(reports));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      dispatch(toggleReservationReport(false));
      console.log(failure);
      if (failure.name === "Error") {
        intimateLoginToast();
      }
    }
  );
};
function addLeadTime(rep) {
  const getLeadTime = ({ bookingDate, arrival }) => {
    var leadTimeInepoch = arrival - bookingDate;
    const result = parseFloat(
      leadTimeInepoch > 0
        ? Math.round(leadTimeInepoch / (24 * 60 * 60 * 1000))
        : 0
    );
    return result;
  };
  let newReport = [];
  newReport = rep.map(r => ({ ...r, leadTime: getLeadTime(r) }));
  return newReport;
}
export const asyncIntgAuditARI = (params, dispatch) => {
  dispatch(setIntgAuditARIReqParams(params));
  dispatch(setIntgAuditARILoading(true));
  if (
    params.clientCode === "Hotelbeds" &&
    params.operation === "RESERVATIONNOTIFRQ_OUT"
  ) {
    return intgAuditARIHBRes(
      params.htConfirmationCode,
      new Date(params.saleDate).getTime() + 19800000
    ).then(
      success => {
        dispatch(setIntgAuditARILoading(false));
        if (success.message) {
          return;
        }
        dispatch(toggleIntgAuditARIHBResPopup(true));
        if (success.body) {
          dispatch(
            setHBResIntgAuditARIContent(success.body.reservationXmlList)
          );
        }
      },
      failure => {
        dispatch(setIntgAuditARILoading(false));
        dispatch(toggleIntgAuditARIHBResPopup(false));
        console.log(failure);
      }
    );
  } else {
    return intgAuditARI(params).then(
      success => {
        dispatch(setIntgAuditARILoading(false));
        if (success.message) {
          return;
        }
        dispatch(toggleIntgAuditARIPopup(true));
        if (success.body) {
          dispatch(setIntgAuditARIContent(success.body));
        }
      },
      failure => {
        dispatch(setIntgAuditARILoading(false));
        dispatch(toggleIntgAuditARIPopup(false));
        console.log(failure);
      }
    );
  }
};

export const asyncGetAllClients = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getAllClients(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setAllClients(success.body));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetAClient = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getAClient(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setClientDetails({ ...success.body, fromServer: true }));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncUpdateClient = (params, history, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return updateAClient(params).then(
    success => {
      toast.success("Successfully updated Client");
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        // dispatch(setAllClients(success.body));
        history.push("/home/demand-client-management");
      }
    },
    failure => {
      toast.error("Failed to update a Client");
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncAddClient = (params, history, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return addAClient(params).then(
    success => {
      toast.success("Successfully added a Client");
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        history.push("/home/demand-client-management");
      }
      asyncGetAllClients(null, dispatch);
    },
    failure => {
      toast.error("Failed to add a Client");
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncGetSourceCommission = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getSourceCommission(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setSourceCommission(success.body));
      }
    },
    failure => {
      toast.error("Failed to add a Client");
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncUpdateClientDetails = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return updateClientDetails(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      asyncGetAllClients(null, dispatch);
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncAddSourceCommission = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return addSourceCommission(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      asyncGetSourceCommission(params.username, dispatch);
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncUpdateSourceCommission = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return updateSourceCommission(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      asyncGetSourceCommission(params.username, dispatch);
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetAllSuppliers = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getAllSuppliers(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setSourceCommission(success.body));
        dispatch(setAllSuppliers(success.body));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncBlackListProperty = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return blacklistProperty(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        toast.success("Successfully Blacklisted a property");
        asyncGetBlacklistedProperties(params.user, dispatch);
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncReListProperty = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  reListProperty(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        toast.success("Successfully Whitelisted a property");
        asyncGetBlacklistedProperties(params.user, dispatch);
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetBlacklistedProperties = (propId, dispatch) => {
  dispatch(setBlackListedPropsLoading(true));
  getBlacklistedProperties(propId).then(
    success => {
      dispatch(setBlackListedPropsLoading(false));
      if (success.body) {
        dispatch(setBlackListedProperties(success.body));
      }
    },
    failure => {
      dispatch(setBlackListedPropsLoading(false));
      console.log(failure);
    }
  );
};
export const asyncGetDirectCommissions = (userName, dispatch) => {
  dispatch(setBlackListedPropsLoading(true));
  getDirectCommissions(userName).then(
    success => {
      dispatch(setBlackListedPropsLoading(false));
      if (success.body) {
        dispatch(setDirectCommissions(success.body.propertyRateplans));
      }
    },
    failure => {
      dispatch(setBlackListedPropsLoading(false));
      console.log(failure);
    }
  );
};
export const asyncAddOrUpdateRateplans = (
  { userName, propertyId, rateplan },
  dispatch
) => {
  dispatch(setBlackListedPropsLoading(true));
  addOrUpdateRateplans(userName, propertyId, rateplan).then(
    success => {
      dispatch(setBlackListedPropsLoading(false));
      if (success.body) {
        asyncGetDirectCommissions(userName, dispatch);
      }
    },
    failure => {
      dispatch(setBlackListedPropsLoading(false));
      console.log(failure);
    }
  );
};
