import update from "immutability-helper";
import {
  IS_LOADING,
  UPDATE_ALL_USERS,
  SET_USER_DETAILS,
  SET_USER_TYPE_PA,
  SET_USER_TYPE_CU
} from "../actions/usersCompaniesActions";

const initialState = {
  isLoading: false,
  allUsers: [],
  userDetails: null,
  isUserTypePA: false,
  selectedPropertyList: [],
  isUserTypeCU: false
};

export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return update(state, { isLoading: { $set: action.isLoading } });
    case UPDATE_ALL_USERS:
      return update(state, { allUsers: { $set: action.allUsers } });
    case SET_USER_DETAILS:
      return update(state, { userDetails: { $set: action.userDetails } });
    case SET_USER_TYPE_PA:
      return update(state, { isUserTypePA: { $set: action.isUserTypePA } });
    case SET_USER_TYPE_CU:
      return update(state, { isUserTypeCU: { $set: action.isUserTypeCU } });
    default:
      return state;
  }
}
