import update from "immutability-helper";
import {
  SET_CANCEL_RESERVATION_LOADING,
  SET_IS_LOADING,
  SET_GET_DETAILS,
  SET_RESERVATION_DETAILS
} from "../actions/cancelReservationActions";

const initialState = {
  cancelReservationLoading: false,
  isLoading: false,
  getDetails: false,
  reservationDetails: []
};

export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case SET_CANCEL_RESERVATION_LOADING:
      return update(state, {
        cancelReservationLoading: { $set: action.cancelReservationLoading }
      });
    case SET_IS_LOADING:
      return update(state, { isLoading: { $set: action.isLoading } });
    case SET_GET_DETAILS:
      return update(state, { getDetails: { $set: action.getDetails } });
    case SET_RESERVATION_DETAILS:
      return update(state, {
        reservationDetails: { $set: action.reservationDetails }
      });
    default:
      return state;
  }
}
