import update from "immutability-helper";
import {
  SET_LOADING,
  SET_RATECODES,
  SET_DATA_UPDATING,
  SET_DISTINCT_DEMAND_CLIENTS,
  SET_RATEPLAN_MANAGEMENT_DATA,
  SET_MAP_DATA,
  SET_RATEPLAN_MANAGEMENT_DATA_BY_RATE_CODE,
  SET_RATEPLAN_MANAGEMENT_DATA_LOADING
} from "../actions/globalRatePlanManagement";

const initialState = {
  isLoading: false,
  rateCodes: [],
  isUpdating: false,
  distinctDemandClients: [],
  rateplanManagementData: [],
  rateplanMap: new Map(),
  isRateplanManagementDataLoading: false
};

export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return update(state, { isLoading: { $set: action.isLoading } });
    case SET_RATEPLAN_MANAGEMENT_DATA_LOADING:
      return update(state, {
        isRateplanManagementDataLoading: {
          $set: action.isRateplanManagementDataLoading
        }
      });
    case SET_RATECODES:
      return update(state, {
        rateCodes: { $set: action.data }
      });
    case SET_DATA_UPDATING:
      return update(state, {
        isUpdating: { $set: action.isUpdating }
      });
    case SET_DISTINCT_DEMAND_CLIENTS:
      return update(state, {
        distinctDemandClients: { $set: action.data }
      });
    case SET_RATEPLAN_MANAGEMENT_DATA:
      return update(state, {
        rateplanManagementData: { $set: action.data }
      });
    case SET_MAP_DATA:
      return update(state, {
        rateplanMap: { $set: action.data }
      });
    case SET_RATEPLAN_MANAGEMENT_DATA_BY_RATE_CODE:
      return update(state, {
        rateplanManagementDataByRateCode: { $set: action.data }
      });
    default:
      return state;
  }
}
