import {
  SET_LOGIN_ERROR,
  DO_AUTHENTICATE,
  LOGOUT,
  SET_IS_LOGIN
} from "../actions/login";
const initialState = {
  isLoggedIn: false,
  errorMessage: null
};

export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case DO_AUTHENTICATE: {
      const state2 = { ...state, isLoggedIn: true };
      return state2;
    }
    case LOGOUT:
      return { ...state, isLoggedIn: false };
    case SET_LOGIN_ERROR:
      return { ...state, errorMessage: action.errorMessage };
    case SET_IS_LOGIN:
      return { ...state, isLoggingIn: action.isLoggingIn };
    default:
      return state;
  }
}
