import { getCacheRates, searchCity } from "../api";
import { toast } from "react-toastify";
export const SET_CACHE_RATES = "SET_CACHE_RATES";
export const SET_SEARCH_CITY_LOADING = "SET_SEARCH_CITY_LOADING";
export const UPDATE_SEARCHED_CITIES = "UPDATE_SEARCHED_CITIES";
export const SET_CITY_NAME_TO_SEARCH = "SET_CITY_NAME_TO_SEARCH";
export const UPDATE_SELECTED_CITY = "UPDATE_SELECTED_CITY";
export const SET_CACHE_RATES_LOADING = "SET_CACHE_RATES_LOADING";
/*
 * action creators
 */
export const setCacheRates = cacheRates => {
  return { type: SET_CACHE_RATES, cacheRates };
};
export const asyncGetCacheRates = (params, dispatch) => {
  dispatch(setCacheRatesLoading(true));
  getCacheRates(params).then(
    success => {
      dispatch(setCacheRatesLoading(false));
      if (success.body) {
        dispatch(setCacheRates(success.body));
      }
    },
    failure => {
      dispatch(setCacheRatesLoading(false));
      console.log(failure);
    }
  );
};

export const asyncSearchCity = (user, dispatch) => {
  dispatch(setSearchCityLoading(true));
  return searchCity(user).then(
    success => {
      dispatch(setSearchCityLoading(false));
      if (success.body) {
        dispatch(updateSearchedCities(success.body.citySuggestions));
      }
    },
    failure => {
      dispatch(setSearchCityLoading(false));
      dispatch(updateSearchedCities([]));

      if (
        failure.response &&
        failure.response.body &&
        failure.response.body.errorCode
      ) {
        toast.error(failure.response.body.errorDesc);
        return;
      }
      toast.error("Search failed because Session Timed out? Login Again?", {
        onClose: () => {
          window.location.href = "/";
        }
      });
    }
  );
};

export const setSearchCityLoading = isSearchCityLoading => ({
  type: SET_SEARCH_CITY_LOADING,
  isSearchCityLoading
});

export const setCacheRatesLoading = isCacheRatesLoading => ({
  type: SET_CACHE_RATES_LOADING,
  isCacheRatesLoading
});

export const updateSearchedCities = neighborhoodRegions => ({
  type: UPDATE_SEARCHED_CITIES,
  neighborhoodRegions
});

export const updateSelectedCity = city => ({
  type: UPDATE_SELECTED_CITY,
  city
});

export const setSearchCityBy = payload => ({
  type: SET_CITY_NAME_TO_SEARCH,
  payload
});
