import {
  getDistinctActiveRateCodes,
  saveGlobalRateplanManagementData,
  getAllActiveInterfaces,
  getClientToRateplanMappingsByRateCode
} from "../api";
import { toast } from "react-toastify";
export const SET_RATECODES = "SET_RATECODES";
export const SET_LOADING = "SET_LOADING";
export const SET_DATA_UPDATING = "SET_DATA_UPDATING";
export const SET_DISTINCT_DEMAND_CLIENTS = "SET_DISTINCT_DEMAND_CLIENTS";
export const SET_RATEPLAN_MANAGEMENT_DATA = "SET_RATEPLAN_MANAGEMENT_DATA";
export const SET_MAP_DATA = "SET_MAP_DATA";
export const SET_RATEPLAN_MANAGEMENT_DATA_BY_RATE_CODE =
  "SET_RATEPLAN_MANAGEMENT_DATA_BY_RATE_CODE";
export const SET_RATEPLAN_MANAGEMENT_DATA_LOADING =
  "SET_RATEPLAN_MANAGEMENT_DATA_LOADING";

export const asyncGetDistinctActiveRateCodes = dispatch => {
  dispatch(setLoading(true));
  getDistinctActiveRateCodes().then(
    success => {
      dispatch(setLoading(false));
      if (success.body) {
        dispatch(setResponseData(success.body));
      }
    },
    failure => {
      dispatch(setLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetClientToRateplanMappingsByRateCode = (
  rateCode,
  dispatch
) => {
  dispatch(setRateplanManagementDataLoading(true));
  getClientToRateplanMappingsByRateCode(rateCode).then(
    success => {
      dispatch(setRateplanManagementDataLoading(false));
      if (success.body) {
        dispatch(setRateplanClientMappingsByRateCode(success.body));
      }
    },
    failure => {
      dispatch(setRateplanManagementDataLoading(false));
      console.log(failure);
    }
  );
};

export const asyncUpdateGlobalRateplanManagementData = (
  rateCode,
  rateplanManagentData,
  dispatch
) => {
  dispatch(setDataUpdating(true));
  saveGlobalRateplanManagementData(rateCode, rateplanManagentData).then(
    success => {
      toast.success("Successfully updated.");
      dispatch(setDataUpdating(false));
      if (success.body) {
      }
    },
    failure => {
      toast.error("Failed to update");
      dispatch(setDataUpdating(false));
      console.log(failure);
    }
  );
};

export const asyncGetAllActiveInterfaces = (rateCode, dispatch) => {
  dispatch(setLoading(true));
  getAllActiveInterfaces().then(
    success => {
      dispatch(setLoading(false));
      if (success.body) {
        let data = success.body;
        dispatch(setDistinctDemandClients(data));
        let rateplanManagementData = buildRateplanManagementData(
          data,
          rateCode
        );
        dispatch(setRateplanManagementData(rateplanManagementData));
        let rateplanMap = buildRateplanClientsMap(rateplanManagementData);
        dispatch(setMapData(rateplanMap));
      }
    },
    failure => {
      dispatch(setLoading(false));
      console.log(failure);
    }
  );
};

export const buildRateplanManagementData = (
  distinctDemandClients,
  rateCodeInfo
) => {
  let rateplanManagementData = [];

  let platformMappings = [];
  let intgClientMappings = [];
  let bookingAPIClientMappings = [];

  if (distinctDemandClients.platforms != null) {
    for (const platform of distinctDemandClients.platforms) {
      let _platform = {
        platformId: platform.id,
        platformName: platform.name,
        active: false,
        commission: 0.0
      };
      platformMappings.push(_platform);
    }
  }

  if (distinctDemandClients.crsInterfaces != null) {
    for (const intgInterface of distinctDemandClients.crsInterfaces) {
      let _intgInterface = {
        clientId: intgInterface.id,
        clientName: intgInterface.name,
        clientType: "Intg",
        active: false,
        commission: 0.0,
        reservationFee: 0.0
      };
      intgClientMappings.push(_intgInterface);
    }
  }

  if (distinctDemandClients.bookingApiInterfaces != null) {
    for (const bookingApiInterface of distinctDemandClients.bookingApiInterfaces) {
      let _bookingApiInterface = {
        clientId: bookingApiInterface.id,
        clientName: bookingApiInterface.name,
        clientType: "Booking API",
        active: false,
        commission: 0.0,
        reservationFee: 0.0
      };
      bookingAPIClientMappings.push(_bookingApiInterface);
    }
  }

  let rateplanData = {
    rateplanId: "0",
    rateCode: rateCodeInfo.rateCode,
    name: rateCodeInfo.name,
    active: false,
    platformRatePlans: platformMappings,
    intgClientRatePlans: intgClientMappings,
    bookingAPIClientRatePlans: bookingAPIClientMappings
  };
  rateplanManagementData.push(rateplanData);
  return rateplanManagementData;
};

const buildRateplanClientsMap = data => {
  let map = new Map();
  for (const rateplan of data) {
    map.set(rateplan.rateplanId, rateplan);
  }
  return map;
};

export const setResponseData = data => {
  return { type: SET_RATECODES, data };
};

export const setRateplanManagementData = data => {
  return {
    type: SET_RATEPLAN_MANAGEMENT_DATA,
    data
  };
};

export const setDistinctDemandClients = data => {
  return { type: SET_DISTINCT_DEMAND_CLIENTS, data };
};

export const setDataUpdating = isUpdating => {
  return { type: SET_DATA_UPDATING, isUpdating };
};

export const setLoading = isLoading => ({
  type: SET_LOADING,
  isLoading
});

export const setRateplanManagementDataLoading = isRateplanManagementDataLoading => ({
  type: SET_RATEPLAN_MANAGEMENT_DATA_LOADING,
  isRateplanManagementDataLoading
});

export const setMapData = map => {
  return { type: SET_MAP_DATA, map };
};

export const setRateplanClientMappingsByRateCode = data => {
  return { type: SET_RATEPLAN_MANAGEMENT_DATA_BY_RATE_CODE, data };
};
