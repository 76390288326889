import request from "superagent";

const CLIENT_MGMT = "/HT-Admin-0.0.1-SNAPSHOT/1.0/";

const postReq = (path, payload) =>
  request
    .post(`${process.env.REACT_APP_API_ROOT}${path}`)
    .type("json")
    .send(payload);

const deleteReq = (path, payload) =>
  request
    .delete(`${process.env.REACT_APP_API_ROOT}${path}`)
    .type("json")
    .send(payload);

export const login = params => postReq(`${CLIENT_MGMT}login`, params);
export const logout = params => deleteReq(`${CLIENT_MGMT}logout`, params);
