// export { default } from "./connected";

import Loadable from 'react-loadable';
import Loading from '../Loading';

const LoadableComponent = Loadable({
  loader: () => import('./connected'),
  loading: Loading
});
export default LoadableComponent;
