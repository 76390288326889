import React, { Component } from "react";

import { AuthProvider } from "../authContext";

class Auth extends Component {
  state = {
    authenticated: sessionStorage.getItem("auth") || false,
    user: {
      role: sessionStorage.getItem("role") || "visitor"
    },
    homePage: sessionStorage.getItem("homePage") || "/home"
  };

  initiateLogin = () => {};

  logout = () => {
    this.setState({
      authenticated: false,
      user: {
        role: "visitor"
      }
    });
  };

  handleAuthentication = state => {
    sessionStorage.setItem("role", state.user.role);
    sessionStorage.setItem("auth", true);
    sessionStorage.setItem("homePage", state.homePage);
    this.setState({
      authenticated: true,
      user: {
        role: "user"
      },
      homePage: "/home",
      ...state
    });
  };

  render() {
    const authProviderValue = {
      ...this.state,
      initiateLogin: this.initiateLogin,
      handleAuthentication: this.handleAuthentication,
      logout: this.logout
    };
    return (
      <AuthProvider value={authProviderValue}>
        {this.props.children}
      </AuthProvider>
    );
  }
}

export default Auth;
