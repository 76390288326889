const rules = {
  visitor: {
    static: [""],
    homePage: "/home"
  },
  user: {
    static: ["reports"],
    dynamic: {
      "reports:edit": ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      }
    },
    homePage: "/home/reservation-report"
  },
  superUser: {
    static: [
      "demandClientConfig",
      "propertyManagement",
      "policies",
      "hotelbedsMapping",
      "rooms",
      "reports",
      "admin-reports",
      "suOnlyControls",
      "validateReservations",
      "updateGuestInformation",
      "cacheLiveStatus",
      "usersCompaniesManagement",
      "cancelReservation",
      "dayRateAvailability",
      "rateplanManagement",
      "rateplansListManagement"
    ],
    homePage: "/home"
  }
};

export default rules;
