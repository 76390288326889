import React, { PureComponent } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import Home from "./components/Home";
import Login from "./components/Login";

import rootReducer from "./reducers";
import Auth from "./components/Auth";
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default class App extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <Auth>
          <Router>
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="switch-wrapper"
            >
              {/* <div> */}
              {/* <Route path="/" exact component={Login} /> */}
              <Route path="/" exact component={Login} />
              <Route path="/home" component={Home} />
              <Route
                path="/Hotel_Trader_Admin/jsp/admin/index.jsp"
                component={RedirectToHome}
              />
              {/* <Route path="/Hotel_Trader_Admin/services/accessDenied" component={RedirectToLogin} /> */}
              {/* <Route path="/Hotel_Trader_Admin/?loginerror" component={RedirectToLogin2} /> */}
              {/* </div> */}
            </AnimatedSwitch>
          </Router>
        </Auth>
      </Provider>
    );
  }
}

const RedirectToHome = () => <Redirect to={{ pathname: "/home" }} />;
// const RedirectToLogin = () => <Redirect to={{ pathname: '/login' }} />;
// const RedirectToLogin2 = () => <Redirect to={{ pathname: '/login' }} />;
