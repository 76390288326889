import request from "superagent";

const HT_ADMIN = "/Hotel_Trader_Admin/services/";
const CLIENT_MGMT = "/HT-Admin-0.0.1-SNAPSHOT/1.0/";

const getRequest = path =>
  request
    .get(`${process.env.REACT_APP_API_ROOT}${path}`)
    .set("Accept", "application/json")
    .withCredentials();
const postRequest = (path, payload) =>
  request
    .post(`${process.env.REACT_APP_API_ROOT}${path}`)
    .type("json")
    .send(payload);
const delRequest = (path, payload) =>
  request
    .delete(`${process.env.REACT_APP_API_ROOT}${path}`)
    .type("json")
    .send(payload);
const putRequest = (path, payload) =>
  request
    .put(`${process.env.REACT_APP_API_ROOT}${path}`)
    .type("json")
    .send(payload);

export const searchProperty = name =>
  getRequest(`${CLIENT_MGMT}admin/client/properties/search/${name}`);
export const getCompanyCodes = () =>
  getRequest(`${HT_ADMIN}admin/reports/client/companycode/all`);
export const getCompanyCodeList = () =>
  getRequest(`${HT_ADMIN}admin/reports/list-companycodes`);
export const getPropertyImages = id =>
  getRequest(`${HT_ADMIN}admin/properties/propertyImages/${id}`);
export const getPropertyAmenities = id =>
  getRequest(`${HT_ADMIN}admin/properties/searchPropertyAmenities/${id}`);
export const getProperty = id =>
  getRequest(`${HT_ADMIN}admin/properties/${id}`);
export const getPropertyInfo = id =>
  getRequest(`${HT_ADMIN}admin/properties/${id}/info`);
export const getTaxes = payload =>
  postRequest(`${HT_ADMIN}admin/properties/getTaxes`, payload);

export const getRooms = id =>
  getRequest(`${HT_ADMIN}admin/properties/fetchRoomType/${id}`);
export const getRoomAmenities = id =>
  getRequest(`${HT_ADMIN}admin/properties/fetchRoomTypeAmenities/${id}`);

export const addRoom = (id, room) =>
  postRequest(`${HT_ADMIN}admin/properties/addRoomType/${id}`, room);

export const updateTaxes = payload =>
  postRequest(`${HT_ADMIN}admin/properties/taxes`, payload);
export const deleteTaxes = payload =>
  delRequest(
    `${HT_ADMIN}admin/properties/taxes?propertyTaxId=${payload.propertyTaxesId}`
  );

export const addPropertyAmenity = payload =>
  postRequest(`${HT_ADMIN}admin/properties/addPropertyAmenity`, payload);
export const updatePropertyAmenities = (propertyId, payload) =>
  postRequest(
    `${HT_ADMIN}admin/properties/saveOrUpdatePropertyAmenities/${propertyId}`,
    payload
  );
export const deleteAmenity = id =>
  getRequest(`${HT_ADMIN}admin/properties/deleteAmenity/${id}`);
export const getMasterAmenities = id =>
  getRequest(`${HT_ADMIN}admin/properties/fetchMasterPropertyAmenities/${id}`);

export const authenticateUser = ({ j_username, j_password }) =>
  request
    .post(
      `${
        process.env.REACT_APP_API_ROOT
      }/Hotel_Trader_Admin/j_spring_security_check`
    )
    .type("form")
    .send({ j_username })
    .send({ j_password });

export const searchReservationReport = params => {
  let path = `${HT_ADMIN}admin/reports/client/searchReservationReport`;
  if (sessionStorage.getItem("role") === "superUser") {
    path = `${HT_ADMIN}admin/reports/searchReservationReport`;
  }
  return postRequest(path, params);
};

export const intgAuditARI = params =>
  postRequest(`${HT_ADMIN}admin/reports/intgAuditAri`, params);
export const intgAuditARIHBRes = (confirmationNumber, bookingDate) =>
  getRequest(
    `${HT_ADMIN}admin/reports/hb/reservation_xml?ht_confirmation_code=${confirmationNumber}&sale_date=${bookingDate}`
  );

export const getAllClients = () => getRequest(`${CLIENT_MGMT}clients/all`);
export const getAClient = user => getRequest(`${CLIENT_MGMT}clients/${user}`);
export const addAClient = params =>
  postRequest(`${CLIENT_MGMT}clients`, params);
export const updateAClient = params =>
  putRequest(`${CLIENT_MGMT}clients/${params.clientCode}`, params);

export const getSourceCommission = username =>
  getRequest(`${CLIENT_MGMT}clients/${username}/clientSupplierCommissions/all`);

export const updateClientDetails = updateAClient;
export const updateSourceCommission = ({ username, row }) =>
  putRequest(
    `${CLIENT_MGMT}clients/${username}/clientSupplierCommissions/${
      row.supplierCode
    }`,
    row
  );
export const addSourceCommission = ({ username, row }) =>
  postRequest(
    `${CLIENT_MGMT}clients/${username}/clientSupplierCommissions`,
    row
  );

export const getAllSuppliers = () => getRequest(`${CLIENT_MGMT}suppliers/all`);
export const getDirectCommissions = user =>
  getRequest(`${CLIENT_MGMT}clients/${user}/rateplans/all`);
export const addOrUpdateRateplans = (user, propId, plan) =>
  postRequest(
    `${CLIENT_MGMT}clients/${user}/property/${propId}/rateplans`,
    plan
  );
export const getBlacklistedProperties = user =>
  getRequest(`${CLIENT_MGMT}clients/${user}/blacklistProperty`);
export const blacklistProperty = ({ user, id }) =>
  postRequest(`${CLIENT_MGMT}clients/${user}/blacklistProperty/${id}`);
export const reListProperty = ({ user, id }) =>
  putRequest(`${CLIENT_MGMT}clients/${user}/whitelistProperty/${id}`);

export const getUserProfile = () => getRequest(`${CLIENT_MGMT}profile`);
export const getCacheRates = params =>
  getRequest(`${CLIENT_MGMT}clients/search/hotels/cache/rates?${params}`);
export const searchCity = city =>
  getRequest(`${CLIENT_MGMT}clients/search/hotels/city-suggestions/${city}`);

//Should be deleted the below endpoints?
export const getAllPolicies = propId =>
  getRequest(`${HT_ADMIN}admin/property/${propId}/policies/all`);
export const getAPolicy = (propId, user) =>
  getRequest(`${HT_ADMIN}admin/property/${propId}/policies/${user}`);
export const addAPolicy = params =>
  postRequest(`${HT_ADMIN}admin/policies`, params);
export const updateAPolicy = params =>
  putRequest(`${HT_ADMIN}admin/policies/${params.policyCode}`, params);
export const deleteAPolicy = params =>
  delRequest(`${HT_ADMIN}admin/policies/${params.policyCode}`, params);

//Users Companies Management apis
export const getAllUsers = () => getRequest(`${HT_ADMIN}admin/list/users`);
export const getUserDetails = username =>
  getRequest(`${HT_ADMIN}admin/get/user?username=${username}`);
export const addUser = params =>
  postRequest(`${HT_ADMIN}admin/add/user`, params);
export const updateUser = params =>
  postRequest(`${HT_ADMIN}admin/edit/user`, params);
export const deleteUser = params =>
  postRequest(`${HT_ADMIN}admin/user/delete`, params);

//cancel reservation apis
export const getReservation = params =>
  request
    .post(`${HT_ADMIN}superuser/sucontrols/cancelReservationInfo`, params)
    .set("Accept", "application/json");
export const cancelReservation = htConfCode =>
  getRequest(
    `${HT_ADMIN}superuser/sucontrols/suCancelReservation/${htConfCode}`
  );

//day rate availability lookup apis
export const getDayRateProperties = () =>
  getRequest(`${HT_ADMIN}admin/checkdayrate/getPropertyIds`);
export const getRatePlanAndRoomIds = propId =>
  getRequest(
    `${HT_ADMIN}admin/checkdayrate/getRatePlanAndRoomIds?propertyId=${propId}`
  );
export const checkDayRate = queryParams =>
  getRequest(`${HT_ADMIN}admin/checkdayrate/check${queryParams}`);
export const getDayRatesYearly = queryParams =>
  getRequest(`${CLIENT_MGMT}rates/dayrates?${queryParams}`);
export const getRatePlansByPid = propId =>
  getRequest(`${CLIENT_MGMT}meta/rateplans/${propId}`);
export const getRoomTypesByPid = propId =>
  getRequest(`${CLIENT_MGMT}meta/roomtypes/${propId}`);

export const saveRateplan = plan =>
  postRequest(`${CLIENT_MGMT}meta/rateplan/`, plan);

export const getPolicies = propId =>
  getRequest(`${CLIENT_MGMT}meta/policies/${propId}`);

export const getRateplanClientMappings = propertyId =>
  getRequest(`${HT_ADMIN}getReztripRatePlansByPlatform/${propertyId}`);

export const saveRateplanManagementData = (
  propertyId,
  rateplanManagementData
) =>
  request
    .post(`${HT_ADMIN}saveRatePlansByPlatform/${propertyId}`)
    .set("Content-Type", "application/json")
    .send(rateplanManagementData);

export const getPropertyRateplans = propertyId =>
  getRequest(`${HT_ADMIN}getRatePlansByProperty/${propertyId}`);

export const hotelbedsPropertyUpload = propertyId =>
  getRequest(`${HT_ADMIN}getRatePlansByProperty/${propertyId}`);

export const getDistinctActiveRateCodes = () =>
  getRequest(`${CLIENT_MGMT}rateplans/ratecodes`);

export const getAllActiveInterfaces = () =>
  getRequest(`${CLIENT_MGMT}interfaces/all/active`);

export const getClientToRateplanMappingsByRateCode = rateCode =>
  request
    .get(`${HT_ADMIN}getClientToRateplanMappingsByRateCode/${rateCode}`)
    .set("X-Requested-With", "XMLHttpRequest")
    .set("Content-Type", "application/json")
    .type("json");

export const saveGlobalRateplanManagementData = (
  rateCode,
  rateplanManagementData
) =>
  request
    .post(`${HT_ADMIN}saveGlobalRateplanManagementData/${rateCode}`)
    .send(rateplanManagementData);

export const checkReservationsExistsForRatePlan = rateplanId =>
  getRequest(`${CLIENT_MGMT}reservations/doesreservationexist/${rateplanId}`);
export const deleteRateplan = rateplanId =>
  postRequest(`${CLIENT_MGMT}meta/rateplan/delete/${rateplanId}`);
export const duplicateRatePlan = (propId, rateplanId, roomTypes) =>
  postRequest(
    `${CLIENT_MGMT}meta/rateplan/duplicate/${propId}/${rateplanId}`,
    roomTypes
  );

export const policyAddOrUpdate = policy =>
  postRequest(`${CLIENT_MGMT}meta/policy`, policy);
export const isPolicyTiedToRateplan = policyId =>
  getRequest(`${CLIENT_MGMT}meta/isPolicyTiedToRateplan/${policyId}`);
export const deletePolicy = policyId =>
  request.delete(`${CLIENT_MGMT}meta/policy/${policyId}`);
