import update from "immutability-helper";
import {
  SET_LOADING,
  SET_RATEPLAN_CLIENT_MAPPINGS,
  SET_MAP_DATA,
  SET_RATEPLANS,
  SET_DATA_UPDATING,
  SET_PROPERTY_FOR_RATEPLAN_DUPLICATION
} from "../actions/rateplanManagement";

const initialState = {
  isLoading: false,
  rateplanManagementData: [],
  rateplanMap: new Map(),
  isUpdating: false,
  propertyId: "",
  propertyRatePlans: [],
  propertyForRateplanDuplication: ""
};
export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return update(state, { isLoading: { $set: action.isLoading } });
    case SET_RATEPLAN_CLIENT_MAPPINGS:
      return update(state, {
        rateplanManagementData: { $set: action.data }
      });
    case SET_MAP_DATA:
      return update(state, {
        rateplanMap: { $set: action.map }
      });
    case SET_DATA_UPDATING:
      return update(state, {
        isUpdating: { $set: action.isUpdating }
      });
    case SET_RATEPLANS:
      return update(state, {
        propertyRatePlans: { $set: action.propertyRatePlans }
      });
    case SET_PROPERTY_FOR_RATEPLAN_DUPLICATION:
      return update(state, {
        propertyForRateplanDuplication: {
          $set: action.propertyForRateplanDuplication
        }
      });

    default:
      return state;
  }
}
