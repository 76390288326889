import update from "immutability-helper";
import {
  SET_SEARCH_PROPERTY_LOADING,
  UPDATE_SELECTED_PROPERTY,
  UPDATE_SEARCHED_PROPERTIES,
  SET_PROPERTY_NAME_TO_SEARCH,
  SET_RESERVATION_REPORT_LOADING,
  SET_SHOW_RESERVATION_REPORT,
  SET_RESERVATION_REPORT,
  SET_INTG_AUDIT_ARI_CONTENT,
  SET_HB_RES_INTG_AUDIT_ARI_CONTENT,
  SET_INTG_AUDIT_ARI_LOADING,
  TOGGLE_INTG_AUDIT_ARI_POPUP,
  TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP,
  SET_INTG_AUDIT_ARI_REQ_PARAMS,
  SET_SELECTED_PROPERTY,
  SET_PROPERTY_IMAGES,
  SET_PROPERTY_AMENITIES,
  UPDATE_REPORT_COLUMNS,
  SET_ALL_CLIENTS,
  SET_SOURCE_COMMISSION,
  SET_RESERVATION_REPORT_REQ_PARAMS,
  SET_ALL_SUPPLIERS,
  TOGGLE_BLACKLISTED_PROP_LIST,
  SET_BLACKLISTED_PROPS,
  SET_BLACKLISTED_PROPS_LOADING,
  SET_DIRECT_COMMISSIONS,
  SET_CLIENT_DETAILS,
  SET_A_CLIENT,
  SET_PROPERTY_TAXES
} from "../actions/home";
import {
  SET_CACHE_RATES,
  SET_SEARCH_CITY_LOADING,
  SET_CACHE_RATES_LOADING,
  UPDATE_SELECTED_CITY,
  UPDATE_SEARCHED_CITIES,
  SET_CITY_NAME_TO_SEARCH
} from "../actions/cache";
import { columns } from "../constants";
import { find } from "lodash";
const intgAuditARIContent = {
  ariBeforeReservation: {},
  ariAfterReservation: {}
};
const initialReservationReportReqParams = {
  propertyName: "",
  lastName: "",
  firstName: "",
  confirmationNumber: "",
  clientConfirmationNumber: "",
  sourceConfirmationNumber: "",
  companyCode: "",
  channels: ["5", "6", "7", "8", "9", "1", "0", "3", "4", "2"],
  checkInFrom: "1900-01-01",
  checkInTo: "2100-12-31",
  checkOutFrom: "1900-01-01",
  checkOutTo: "2100-12-31",
  bookingFrom: "1900-01-01",
  bookingTo: "2100-12-31",
  cancelationFrom: "1900-01-01",
  cancelationTo: "2100-12-31",
  incldCancldReserv: false,
  includeorExcludeorOnlyCancelRes: 0
};
const initialState = {
  selectedProperty: {},
  propertyAmenities: [],
  propertyImages: [],
  properties: [],
  showIntgAuditARIPopup: false,
  showIntgAuditARIHBResPopup: false,
  reservationReports: [],
  intgAuditARIContent,
  hbResintgAuditARIContent: [],
  reservationReportColumns: columns,
  allClients: [],
  allSuppliers: [],
  sourceCommission: [],
  reservationReportReqParams: initialReservationReportReqParams,
  initialReservationReportReqParams,
  searchPropertyBy: "",
  clientDetails: null,
  cacheRates: {},
  neighborhoodRegions: [],
  searchCityBy: ""
};

export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SELECTED_PROPERTY:
      return update(state, { selectedProperty: { $set: action.property } });
    case SET_PROPERTY_NAME_TO_SEARCH:
      return update(state, {
        searchPropertyBy: { $set: action.payload.value },
        searchPropertyId: { $set: action.payload.id }
      });

    case SET_SEARCH_PROPERTY_LOADING:
      return update(state, {
        isSearchPropertyLoading: { $set: action.isSearchPropertyLoading }
      });

    case UPDATE_SEARCHED_PROPERTIES:
      return update(state, {
        properties: { $set: action.properties },
        isSearchPropertyLoading: { $set: action.isSearchPropertyLoading }
      });
    case UPDATE_SELECTED_CITY:
      return update(state, { selectedCity: { $set: action.cityName } });

    case SET_CITY_NAME_TO_SEARCH:
      return update(state, {
        searchCityBy: { $set: action.payload.value },
        searchCityId: { $set: action.payload.id }
      });

    case SET_SEARCH_CITY_LOADING:
      return update(state, {
        isSearchCityLoading: { $set: action.isSearchCityLoading }
      });

    case SET_CACHE_RATES_LOADING:
      return update(state, {
        isCacheRatesLoading: { $set: action.isCacheRatesLoading }
      });

    case UPDATE_SEARCHED_CITIES:
      return update(state, {
        neighborhoodRegions: { $set: action.neighborhoodRegions },
        isSearchCityLoading: { $set: action.isSearchCityLoading }
      });

    case SET_RESERVATION_REPORT_LOADING:
      return update(state, {
        isReservationReportLoading: { $set: action.isReservationReportLoading }
      });

    case SET_SHOW_RESERVATION_REPORT:
      return update(state, {
        showReservationReport: { $set: action.showReservationReport }
      });
    case SET_RESERVATION_REPORT:
      return update(state, {
        reservationReports: { $set: action.reservationReports }
      });
    case SET_RESERVATION_REPORT_REQ_PARAMS:
      return update(state, {
        reservationReportReqParams: {
          $set:
            action.reservationReportReqParams ||
            initialReservationReportReqParams
        }
      });

    case SET_INTG_AUDIT_ARI_LOADING:
      return update(state, {
        isIntgAuditARILoading: { $set: action.isIntgAuditARILoading }
      });
    case SET_INTG_AUDIT_ARI_CONTENT:
      return update(state, {
        intgAuditARIContent: {
          $set: action.intgAuditARIContent || intgAuditARIContent
        }
      });
    case SET_HB_RES_INTG_AUDIT_ARI_CONTENT:
      return update(state, {
        hbResintgAuditARIContent: {
          $set: action.hbResintgAuditARIContent || []
        }
      });

    case TOGGLE_INTG_AUDIT_ARI_POPUP:
      return update(state, {
        showIntgAuditARIPopup: { $set: action.showIntgAuditARIPopup }
      });
    case TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP:
      return update(state, {
        showIntgAuditARIHBResPopup: { $set: action.showIntgAuditARIHBResPopup }
      });
    case SET_INTG_AUDIT_ARI_REQ_PARAMS:
      return update(state, {
        intgAuditARIReqParams: { $set: action.intgAuditARIReqParams }
      });
    case SET_SELECTED_PROPERTY:
      return update(state, {
        selectedProperty: { $set: action.selectedProperty }
      });
    case SET_PROPERTY_TAXES:
      return update(state, {
        propertyTaxes: { $set: action.propertyTaxes }
      });
    case SET_PROPERTY_AMENITIES:
      return update(state, {
        propertyAmenities: { $set: action.propertyAmenities }
      });
    case SET_PROPERTY_IMAGES:
      return update(state, {
        propertyImages: { $set: action.propertyImages }
      });
    case SET_ALL_CLIENTS:
      return update(state, {
        allClients: { $set: action.allClients }
      });
    case SET_A_CLIENT:
      return update(state, {
        clientToEdit: { $set: action.clientToEdit }
      });
    case SET_ALL_SUPPLIERS:
      return update(state, {
        allSuppliers: { $set: action.allSuppliers }
      });

    case TOGGLE_BLACKLISTED_PROP_LIST:
      return update(state, {
        showBlacklistedProperties: { $set: !state.showBlacklistedProperties }
      });

    case SET_BLACKLISTED_PROPS:
      return update(state, {
        blacklistedProperties: { $set: action.blacklistedProperties }
      });
    case SET_DIRECT_COMMISSIONS:
      return update(state, {
        directCommissions: { $set: action.directCommissions }
      });

    case SET_BLACKLISTED_PROPS_LOADING:
      return update(state, {
        blacklistedPropertiesLoading: {
          $set: action.blacklistedPropertiesLoading
        }
      });

    case SET_SOURCE_COMMISSION:
      const allSuppliers = state.allSuppliers;
      const sourceCommission = allSuppliers.map(sup => {
        let foundCommission = find(action.sourceCommission, {
          supplierCode: sup.code
        });
        if (foundCommission) {
          foundCommission.supplierName = sup.supplierName;
        } else {
          foundCommission = {
            supplierCode: sup.code,
            supplierName: sup.supplierName,
            isNotInDb: true
          };
        }
        return foundCommission;
      });
      return update(state, {
        sourceCommission: { $set: sourceCommission }
      });

    case SET_CLIENT_DETAILS:
      return update(state, {
        clientDetails: { $set: action.clientDetails }
      });
    case UPDATE_REPORT_COLUMNS:
      return update(state, {
        reservationReportColumns: { $set: action.reservationReportColumns }
      });
    case SET_CACHE_RATES:
      return update(state, {
        cacheRates: { $set: action.cacheRates }
      });

    default:
      return state;
  }
}
