import dateFormat from "dateformat";
import { format } from "date-fns";
export const RESERVATION_REPORT = "RESERVATION_REPORT";
export const SOURCE_COMMISSION_MANAGEMENT = "SOURCE_COMMISSION_MANAGEMENT";
export const PROPERTY_MANAGEMENT = "PROPERTY_MANAGEMENT";
export const CACHE_LIVE_STATUS = "CACHE_LIVE_STATUS";
export const USER_MANAGEMENT = "USER_MANAGEMENT";
export const RATEPLAN_MANAGEMENT = "RATEPLAN_MANAGEMENT";

export const getRoundedToTwoDecimals = ({ value }) => {
  const roundedValue = new Intl.NumberFormat("en-US", {
    // style: "currency",
    // currency: "USD",
    maximumFractionDigits: 2
  }).format(value);
  return roundedValue;
};
export const getFormattedDate = (value, format = "yyyy-mm-dd") => {
  if (value) {
    const dateStr = dateFormat(new Date(value), format);
    return dateStr;
  }
  return "";
};
export const getFormattedDateFromString = value => {
  const FORMAT_STR = "yyyy-mm-dd' 'HH:MM:ss";
  if (value) {
    const dateStr = format(new Date(value), FORMAT_STR);
    return dateStr;
  }
  return "";
};
export const getFormattedDateTime = value =>
  (value && dateFormat(new Date(value), "yyyy-mm-dd' 'HH:MM:ss")) || "";
export const getFormattedUTCDateTime = value =>
  (value && dateFormat(new Date(value), "UTC:yyyy-mm-dd HH:MM:ss")) || "";
export const getFormattedTime = value =>
  (value && dateFormat(new Date(value), "HH:MM:ss")) || "";

export const columns = [
  { text: "Company Code", dataField: "companyCode" },
  {
    text: "Property Name",
    dataField: "propertyName",
    width: 150
  },
  {
    text: "Confirmation Number",
    dataField: "confirmationNumber",
    width: 150
  },
  {
    text: "Property Code",
    dataField: "propertyCode",
    width: 150
  },
  { text: "Intg Conf #", dataField: "otaConfirmationCode" }
];
export const invoicableColumns = [
  "companyCode",
  "propertyName",
  "reservationId",
  "otaConfirmationCode",
  "cancellable",
  "guestName",
  "noOfGuests",
  "bookingDate",
  "arrival",
  "departure",
  "leadTime",
  "rateCode",
  "room",
  "clientSalePrice",
  "taxes",
  "grossPrice"
];
export const hiddenColumns = [
  "reservationId",
  "itineraryId",
  "resId",
  "ratePlanId",
  "htCommission",
  "roomTypeId",
  "cost",
  "costTax",
  "totalCost",
  "companyCode",
  "channel",
  "fees"
];
export const getDateString = date => {
  return dateFormat(date, "isoDate");
};

export const validateEmail = email => {
  if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(email)) {
    return true;
  }
  return false;
};
