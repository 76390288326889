import {
  getRateplanClientMappings,
  saveRateplanManagementData,
  getPropertyRateplans
} from "../api";
import { toast } from "react-toastify";
export const SET_RATEPLAN_CLIENT_MAPPINGS = "SET_RATEPLAN_CLIENT_MAPPINGS";
export const SET_LOADING = "SET_LOADING";
export const SET_MAP_DATA = "SET_MAP_DATA";
export const UPDATE_RATEPLAN_MANAGEMENT_DATA =
  "UPDATE_RATEPLAN_MANAGEMENT_DATA";
export const SET_DATA_UPDATING = "SET_DATA_UPDATING";
export const SET_RATEPLANS = "SET_RATEPLANS";
export const SET_PROPERTY_FOR_RATEPLAN_DUPLICATION =
  "SET_PROPERTY_FOR_RATEPLAN_DUPLICATION";

export const asyncGetRateplanClientMappings = (propertyId, dispatch) => {
  dispatch(setLoading(true));
  getRateplanClientMappings(propertyId).then(
    success => {
      dispatch(setLoading(false));
      if (success.body) {
        let data = success.body;
        let map = buildRateplanClientsMap(data);
        dispatch(setResponseData(success.body));
        dispatch(setMapData(map));
      }
    },
    failure => {
      dispatch(setLoading(false));
      console.log(failure);
    }
  );
};

export const asyncUpdateRateplanManagementData = (
  propertyId,
  clientManagementData,
  dispatch
) => {
  dispatch(setDataUpdating(true));
  return saveRateplanManagementData(propertyId, clientManagementData).then(
    success => {
      toast.success("Successfully updated.");
      dispatch(setDataUpdating(false));
      if (success.body) {
        //dispatch(asyncGetRateplanClientMappings(propertyId));
        //  history.push("/home/rateplan-management");
      }
    },
    failure => {
      toast.error("Failed to update");
      dispatch(setDataUpdating(false));
      console.log(failure);
    }
  );
};

const buildRateplanClientsMap = data => {
  let map = new Map();
  for (const rateplan of data) {
    map.set(rateplan.rateplanId, rateplan);
  }
  return map;
};

export const setResponseData = data => ({
  type: SET_RATEPLAN_CLIENT_MAPPINGS,
  data
});

export const setDataUpdating = isUpdating => {
  return { type: SET_DATA_UPDATING, isUpdating };
};

export const setMapData = map => ({ type: SET_MAP_DATA, map });
export const setLoading = isLoading => ({
  type: SET_LOADING,
  isLoading
});

export const setRatePlans = propertyRatePlans => ({
  type: SET_RATEPLANS,
  propertyRatePlans
});

export const updateSelectedPropertyForDuplication = property => ({
  type: SET_PROPERTY_FOR_RATEPLAN_DUPLICATION,
  propertyForRateplanDuplication: property
});

export const asyncGetPropertyRatePlans = (propertyId, dispatch) => {
  dispatch(setLoading(true));
  getPropertyRateplans(propertyId).then(
    success => {
      dispatch(setLoading(false));
      if (success.body) {
        dispatch(setRatePlans(success.body));
      }
    },
    failure => {
      dispatch(setLoading(false));
      console.log(failure);
    }
  );
};
