import { authenticateUser, getUserProfile } from "../api";
import rules from "../rbac-rules";
import { login, logout } from "../api/login";

export const DO_AUTHENTICATE = "DO_AUTHENTICATE";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_IS_LOGIN = "SET_IS_LOGIN";
export const LOGOUT = "LOGOUT";

/*
 * action creators
 */

export const doAuthenticate = () => ({ type: DO_AUTHENTICATE });

export const setLoginError = errorMessage => {
  return { type: SET_LOGIN_ERROR, errorMessage };
};
export const setIsLoggingIn = isLoggingIn => {
  return { type: SET_IS_LOGIN, isLoggingIn };
};
export const handleLogout = () => {
  return { type: LOGOUT };
};
export const asyncGetUserProfile = (callback, dispatch) => {
  dispatch(setIsLoggingIn(true));
  getUserProfile().then(
    success => {
      dispatch(setIsLoggingIn(false));
      if (success.body) {
        const role = success.body.roles.includes("SUPER_USER")
          ? "superUser"
          : "user";
        callback({
          authenticated: true,
          user: {
            role,
            ...success.body
          },
          homePage: rules[role].homePage
        });
      }
    },
    failure => {
      dispatch(setIsLoggingIn(false));
      console.log(failure);
    }
  );
};

export function asyncAuthenticateUser(user, dispatch, callback) {
  dispatch(setLoginError(""));
  dispatch(setIsLoggingIn(true));
  return authenticateUser(user)
    .then(resp => {
      dispatch(setIsLoggingIn(false));
      if (resp.xhr && resp.xhr.responseURL) {
        if (
          resp.xhr.responseURL.includes(
            "Hotel_Trader_Admin/jsp/admin/index.jsp"
          )
        ) {
          asyncClientMgmtLogin(user, dispatch, callback);
          dispatch(doAuthenticate());
          return;
        } else {
          dispatch(setLoginError("Wrong credentials"));
          return;
        }
      }
      dispatch(setLoginError("Unable to login"));
    })
    .catch(error => {
      dispatch(setIsLoggingIn(false));
      console.error(error);
      dispatch(setLoginError("Unable to login"));
    });
}

export function asyncClientMgmtLogin(user, dispatch, callback) {
  dispatch(setLoginError(""));
  dispatch(setIsLoggingIn(true));
  const newUser = { username: user.j_username, password: user.j_password };
  return login(newUser)
    .then(resp => {
      dispatch(setIsLoggingIn(false));
      if (resp.body) {
        sessionStorage.setItem("jwtToken", resp.body.accessToken);
        asyncGetUserProfile(callback, dispatch);
        return;
      }
      dispatch(setLoginError("Unable to login"));
    })
    .catch(error => {
      dispatch(setIsLoggingIn(false));
      console.error(error);
      dispatch(setLoginError("Unable to login"));
    });
}
export function asyncClientMgmtLogout(dispatch, callback) {
  dispatch(setIsLoggingIn(true));
  return logout()
    .then(resp => {
      dispatch(setIsLoggingIn(false));
      sessionStorage.clear();
      callback();
    })
    .catch(error => {
      dispatch(setIsLoggingIn(false));
      console.error(error);
      sessionStorage.clear();
      callback();
    });
}
